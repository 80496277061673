////
/// @group themes
/// @access public
/// @author <a href="https://github.com/simeonoff" target="_blank">Simeon Simeonoff</a>
/// @author <a href="https://github.com/desig9stein" target="_blank">Marin Popov</a>
////

/// @param {Map} $palette [$default-palette] - The palette used as basis for styling the component.
/// @param {Map} $schema [$light-schema] - The schema used as basis for styling the component.
///
/// @param {Color} $background [null] - The list background color.
/// @param {Color} $header-background [null] - The list header background color.
/// @param {Color} $header-text-color [null] - The list header text color.
///
/// @param {Color} $item-background [null] - The list item background color.
/// @param {Color} $item-background-hover [null] - The list item hover background color.
/// @param {Color} $item-background-active [null] - The active list item background color.
///
/// @param {Color} $item-text-color [null] - The list item text color.
/// @param {Color} $item-text-color-hover [null] - The list item hover text color.
/// @param {Color} $item-text-color-active [null] - The active list item text color.
///
/// @param {Color} $item-title-color [null] - The list item title color.
/// @param {Color} $item-title-color-hover [null] - The list item hover title color.
/// @param {Color} $item-title-color-active [null] - The active list item title color.
///
/// @param {Color} $item-subtitle-color [null] - The list item subtitle color.
/// @param {Color} $item-subtitle-color-hover [null] - The list item hover subtitle color.
/// @param {Color} $item-subtitle-color-active [null] - The active list item subtitle color.
///
/// @param {Color} $item-action-color [null] - The list item action color.
/// @param {Color} $item-action-color-hover [null] - The list item hover action color.
/// @param {Color} $item-action-color-active [null] - The active list item action color.
///
/// @param {Color} $item-thumbnail-color [null] - The list item thumbnail color.
/// @param {Color} $item-thumbnail-color-hover [null] - The list item hover thumbnail color.
/// @param {Color} $item-thumbnail-color-active [null] - The active list item thumbnail color.
///
/// @param {border-radius} $border-radius [null] - The border radius used for list component.
/// @param {border-radius} $item-border-radius [null] - The border radius used for list item.
///
/// @param {Color} $border-width [null] - The list border width.
/// @param {Number} $border-color [null] - The list border color.
///
/// @requires $default-palette
/// @requires $light-schema
/// @requires apply-palette
/// @requires extend
/// @requires hexrgba
/// @requires text-contrast
/// @requires round-borders
/// @requires luminance
///
/// @example scss Change the list background color
///   $my-list-theme: igx-list-theme($background: black);
///   // Pass the theme to the igx-list component mixin
///   @include igx-list($my-list-theme);
@function igx-list-theme(
    $palette: $default-palette,
    $schema: $light-schema,

    $border-radius: null,
    $item-border-radius: null,
    $background: null,
    $header-background: null,
    $header-text-color: null,
    $item-background: null,
    $item-background-hover: null,
    $item-background-active: null,
    $item-text-color: null,
    $item-text-color-hover: null,
    $item-text-color-active: null,
    $item-title-color: null,
    $item-title-color-hover: null,
    $item-title-color-active: null,
    $item-subtitle-color: null,
    $item-subtitle-color-hover: null,
    $item-subtitle-color-active: null,
    $item-action-color: null,
    $item-action-color-hover: null,
    $item-action-color-active: null,
    $item-thumbnail-color: null,
    $item-thumbnail-color-hover: null,
    $item-thumbnail-color-active: null,
    $border-color: null,
    $border-width: null,
) {
    $name: 'igx-list';
    $list-schema: ();

    @if map-has-key($schema, $name) {
        $list-schema: map-get($schema, $name);
    } @else {
        $list-schema: $schema;
    }

    $theme: apply-palette($list-schema, $palette);

    $border-radius: round-borders(
        if($border-radius, $border-radius, map-get($list-schema, 'border-radius')), 0, 24px
    );

    $item-border-radius: round-borders(
        if($item-border-radius, $item-border-radius, map-get($list-schema, 'item-border-radius')), 0, 24px
    );

    @if not($header-background) and $background {
        $header-background: $background;
    }

    @if not($header-background) and $item-background {
        $header-background: $item-background;
    }

    @if not($item-background) and $background {
        $item-background: $background;
    }

    @if not($background) and $item-background {
        $background: $item-background;
    }

    @if not($item-background-hover) and $item-background {
        @if type-of($item-background) == 'color' and luminance($item-background) < .5 {
            $item-background-hover: lighten-color($item-background, 8%);
        } @else {
            $item-background-hover: darken-color($item-background, 8%);
        }
    }

    @if not($item-background-active) and $item-background {
        @if type-of($item-background) == 'color' and luminance($item-background) < .5 {
            $item-background-active: lighten-color($item-background, 8%);
        } @else {
            $item-background-active: darken-color($item-background, 8%);
        }
    }

    @if not($header-text-color) and $header-background {
        $header-text-color: text-contrast($header-background);
    }

    @if not($item-text-color) and $item-background {
        $item-text-color: text-contrast($item-background);
    }

    @if not($item-title-color) and $item-background {
        $item-title-color: text-contrast($item-background);
    }

    @if not($item-action-color) and $item-background {
        $item-action-color: text-contrast($item-background);
    }

    @if not($item-thumbnail-color) and $item-background {
        $item-thumbnail-color: text-contrast($item-background);
    }

    @if not($item-subtitle-color) and $item-background {
        $item-subtitle-color: rgba(text-contrast($item-background), .74);
    }

    @if not($item-subtitle-color) and $item-text-color {
        $item-subtitle-color: $item-text-color;
    }

    @if not($item-text-color-hover) and $item-background-hover {
        $item-text-color-hover: text-contrast($item-background-hover);
    }

    @if not($item-title-color-hover) and $item-background-hover {
        $item-title-color-hover: text-contrast($item-background-hover);
    }

    @if not($item-action-color-hover) and $item-background-hover {
        $item-action-color-hover: text-contrast($item-background-hover);
    }

    @if not($item-thumbnail-color-hover) and $item-background-hover {
        $item-thumbnail-color-hover: text-contrast($item-background-hover);
    }

    @if not($item-subtitle-color-hover) and $item-background-hover {
        $item-subtitle-color-hover: rgba(text-contrast($item-background-hover), .74);
    }

    @if not($item-subtitle-color-hover) and $item-text-color-hover {
        $item-subtitle-color-hover: $item-text-color-hover;
    }

    @if not($item-text-color-active) and $item-background-active {
        $item-text-color-active: text-contrast($item-background-active);
    }

    @if not($item-title-color-active) and $item-background-active {
        $item-title-color-active: text-contrast($item-background-active);
    }

    @if not($item-action-color-active) and $item-background-active {
        $item-action-color-active: text-contrast($item-background-active);
    }

    @if not($item-thumbnail-color-active) and $item-background-active {
        $item-thumbnail-color-active: text-contrast($item-background-active);
    }

    @if not($item-subtitle-color-active) and $item-background-active {
        $item-subtitle-color-active: rgba(text-contrast($item-background-active), .74);
    }

    @if not($item-subtitle-color-active) and $item-text-color-active {
        $item-subtitle-color-active: $item-text-color-active;
    }

    @return extend($theme, (
        name: $name,
        palette: $palette,
        border-radius: $border-radius,
        item-border-radius: $item-border-radius,
        background: $background,
        header-background: $header-background,
        header-text-color: $header-text-color,
        item-background: $item-background,
        item-background-hover: $item-background-hover,
        item-background-active: $item-background-active,
        item-text-color: $item-text-color,
        item-text-color-hover: $item-text-color-hover,
        item-text-color-active: $item-text-color-active,
        item-title-color:$item-title-color,
        item-title-color-hover:$item-title-color-hover,
        item-title-color-active:$item-title-color-active,
        item-subtitle-color: $item-subtitle-color,
        item-subtitle-color-hover: $item-subtitle-color-hover,
        item-subtitle-color-active: $item-subtitle-color-active,
        item-action-color: $item-action-color,
        item-action-color-hover: $item-action-color-hover,
        item-action-color-active: $item-action-color-active,
        item-thumbnail-color: $item-thumbnail-color,
        item-thumbnail-color-hover: $item-thumbnail-color-hover,
        item-thumbnail-color-active: $item-thumbnail-color-active,
        border-color: $border-color,
        border-width: $border-width,
    ));
}

/// @param {Map} $theme - The theme used to style the component.
/// @requires em
/// @requires --var
@mixin igx-list($theme) {
    @include igx-root-css-vars($theme);

    $variant: map-get($theme, variant);
    $bootstrap-theme: $variant == 'bootstrap';

    $left: if-ltr(left, right);
    $right: if-ltr(right, left);

    $list-empty-padding: (
        comfortable: rem(16px),
        cosy: rem(8px),
        compact: rem(4px)
    );

    $list-item-padding: (
        comfortable: rem(8px) rem(16px),
        cosy: rem(4px) rem(8px),
        compact: rem(2px) rem(4px)
    );

    $list-icon-margin: (
        comfortable: rem(8px) rem(16px) rem(8px) 0,
        cosy: rem(6px) rem(8px) rem(6px) 0,
        compact: rem(4px) rem(4px) rem(4px) 0
    );

    $list-icon-margin-rtl: (
        comfortable: rem(8px) 0 rem(8px) rem(16px),
        cosy: rem(6px) 0 rem(6px) rem(8px),
        compact: rem(4px) 0 rem(4px) rem(4px)
    );

    $list-line-margin: (
        comfortable: 0 0 0 rem(16px),
        cosy: 0 0 0 rem(12px),
        compact: 0 0 0 rem(8px)
    );

    $list-line-margin-rtl: (
        comfortable: 0 rem(16px) 0 0 ,
        cosy: 0 rem(12px) 0 0 ,
        compact: 0 rem(8px) 0 0
    );

    %igx-list {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        background: --var($theme, 'background');
        height: 100%;
        overflow: hidden;
        z-index: 0;
        border-radius: --var($theme, 'border-radius');

        @if $bootstrap-theme {
            border: --var($theme, 'border-width') solid --var($theme, 'border-color');
        }
    }

    %igx-list--empty {
        justify-content: center;
        align-items: center;
    }

    %igx-list__message--empty {
        text-align: center;
        color: --var($theme, 'item-text-color');
        padding: map-get($list-empty-padding, 'comfortable');
        z-index: 1;
    }

    %igx-list-header {
        display: flex;
        align-items: center;
        color: --var($theme, 'header-text-color');
        background: --var($theme, 'header-background');
        padding: map-get($list-item-padding, 'comfortable');
        user-select: none;
    }

    %igx-list-header--cosy {
        padding: map-get($list-item-padding, 'cosy');
    }

    %igx-list-header--compact {
        padding: map-get($list-item-padding, 'compact');
    }

    %igx-list-item-base {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        border-radius: --var($theme, 'item-border-radius');
        color: --var($theme, 'item-text-color');
        background: --var($theme, 'item-background');

        &:hover {
            color: --var($theme, 'item-text-color-hover');
            background: --var($theme, 'item-background-hover');

            %igx-list__item-lines {
                color: currentColor;
            }

            %igx-list__item-line-title {
                color: --var($theme, 'item-title-color-hover');
            }

            %igx-list__item-line-subtitle {
                color: --var($theme, 'item-subtitle-color-hover');
            }

            %igx-list__item-actions {
                color: --var($theme, 'item-action-color-hover');

                igx-icon {
                    color: --var($theme, 'item-action-color-hover')
                }
            }

            %igx-list__item-thumbnail {
                color: --var($theme, 'item-thumbnail-color-hover');

                igx-icon {
                    color: --var($theme, 'item-thumbnail-color-hover')
                }
            }
        }
    }

    %igx-list-item-pan {
        position: absolute;
        visibility: hidden;
        display: flex;
        z-index: 1;
    }

    %igx-list__item-lines {
        color: currentColor;
        display: flex;
        flex-direction: column;
        flex: 1 0 0%;

        &:empty {
            display: none;
        }
    }

    %igx-list__item-line-subtitle {
        color: --var($theme, 'item-subtitle-color');
        opacity: .74
    }

    %igx-list__item-line-title {
        color: --var($theme, 'item-title-color');
    }

    %igx-list__item-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        color: --var($theme, 'item-action-color');

        > * {
            margin-#{$left}: rem(8px);
        }

        &:empty {
            display: none;
        }

        igx-icon {
            color: --var($theme, 'item-action-color')
        }
    }

    %igx-list-item-content {
        display: flex;
        align-items: center;
        position: relative;
        padding: map-get($list-item-padding, 'comfortable');
        border-radius: --var($theme, 'item-border-radius');
        background: inherit;
        z-index: 2;
    }

    %igx-list-item-content--compact {
        padding: map-get($list-item-padding, 'compact');
    }

    %igx-list-item-content--cosy {
        padding: map-get($list-item-padding, 'cosy');
    }

    %igx-list__item-thumbnail {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;
        padding: 0;
        color: --var($theme, 'item-thumbnail-color');

        > igx-icon {
            @include if-ltr() {
                margin: map-get($list-icon-margin, 'comfortable');
            }

            @include if-rtl() {
                margin: map-get($list-icon-margin-rtl, 'comfortable');
            }
        }

        > igx-avatar {
            > igx-icon {
                margin: 0;
            }
        }

        &:empty {
            display: none;
        }
    }

    %igx-list__item-thumbnail:not(:empty) + %igx-list__item-lines {
        @include if-ltr() {
            margin: map-get($list-line-margin, 'comfortable');
        }

        @include if-rtl() {
            margin: map-get($list-line-margin-rtl, 'comfortable');
        }
    }

    %igx-list__item-thumbnail--cosy:not(:empty) + %igx-list__item-lines {
        @include if-ltr() {
            margin: map-get($list-line-margin, 'cosy');
        }

        @include if-rtl() {
            margin: map-get($list-line-margin-rtl, 'cosy');
        }
    }

    %igx-list__item-thumbnail--compact:not(:empty) + %igx-list__item-lines {
        @include if-ltr() {
            margin: map-get($list-line-margin, 'compact');
        }

        @include if-rtl() {
            margin: map-get($list-line-margin-rtl, 'compact');
        }
    }

    %igx-list__item-thumbnail--cosy {
        @include if-ltr() {
            > igx-icon {
                margin: map-get($list-icon-margin, 'cosy');
            }
        }

        @include if-rtl() {
            > igx-icon {
                margin: map-get($list-icon-margin-rtl, 'cosy');
            }
        }
    }

    %igx-list__item-thumbnail--compact {
        @include if-ltr() {
            > igx-icon {
                margin: map-get($list-icon-margin, 'compact');
            }
        }

        @include if-rtl() {
            > igx-icon {
                margin: map-get($list-icon-margin-rtl, 'compact');
            }
        }
    }

    %igx-list__item-actions--cosy {
        > * {
            margin-#{$left}: rem(6px);
        }
    }

    %igx-list__item-actions--compact {
        > * {
            margin-#{$left}: rem(4px);
        }
    }

    %igx-list__item-lines + %igx-list__item-actions {
        margin-#{$left}: rem(8px);
    }

    %igx-list-item-content--active {
        %igx-list__item-line-title {
            color: --var($theme, 'item-title-color-active')
        }

        %igx-list__item-line-subtitle {
            color: --var($theme, 'item-subtitle-color-active')
        }

        %igx-list__item-actions {
            color: --var($theme, 'item-action-color-active');

            igx-icon {
                color: --var($theme, 'item-action-color-active')
            }
        }

        %igx-list__item-thumbnail {
            color: --var($theme, 'item-thumbnail-color-active');

            igx-icon {
                color: --var($theme, 'item-thumbnail-color-active')
            }
        }

        color: --var($theme, 'item-text-color-active');
        background: --var($theme, 'item-background-active');
        z-index: 3;
    }

    %igx-list-item-content--inactive {
        transition: transform .3s $ease-out-quad;
    }
}

/// Adds typography styles for the igx-list component.
/// Uses the 'caption' and 'subtitle-1'
/// categories from the typographic scale.
/// @group typography
/// @param {Map} $type-scale - A typographic scale as produced by igx-type-scale.
/// @param {Map} $categories [(header: 'overline', item: 'subtitle-1', title: 'subtitle-1', subtitle: 'caption')] - The categories from the typographic scale used for type styles.
/// @requires {mixin} igx-type-style
@mixin igx-list-typography(
    $type-scale,
    $categories: (
        header: 'overline',
        item: 'subtitle-1',
        title: 'subtitle-1',
        subtitle: 'caption'
    )
) {
    $header: map-get($categories, 'header');
    $item: map-get($categories, 'item');
    $title: map-get($categories, 'title');
    $subtitle: map-get($categories, 'subtitle');

    %igx-list-header {
        @include igx-type-style($type-scale, $header) {
            margin: 0;
        }
    }

    %igx-list-item {
        @include igx-type-style($type-scale, $item) {
            margin: 0;
        }
    }

    %igx-list__item-lines,
    %igx-list__item-line-title {
        @include igx-type-style($type-scale, $title) {
            margin: 0;
        }
    }

    %igx-list__item-line-subtitle {
        @include igx-type-style($type-scale, $subtitle) {
            margin: 0;
        }
    }
}
