$card-title-height: 44px;
$card-header-font-size: 16px;
$card-text-color: rgba(0,0,0,.54);

.card {
  @include scrollbars(.4em, rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0.7));
  color: $card-text-color;
  border: 0;
  position: relative;
  margin-bottom: 24px;
  box-shadow: $bootstrap-panel-shadow;

  &.small-card {
    height: $small-card-height;
  }
  &.xsmall-card {
    height: $xsmall-card-height;
  }
  &.medium-card {
    height: $medium-card-height;
  }
  &.xmedium-card {
    height: $extra-medium-card-height;
  }
  &.large-card {
    height: $large-card-height;
  }
  &.viewport100 {
    height: calc(100vh - 218px);
  }

  &.with-scroll {
    .card-body {
      height: calc(100% - #{$card-title-height});
      overflow-y: auto;
    }
  }
}

.card {
  > .card-body {
    padding: 16px 22px;
    height: 100%;
  }
  > .card-header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    &:first-child {
      border-radius: 8px 8px 0 0 ;
    }
  }
  > .card-footer {
    color: $default-text;
  }
}

.card-header, .card-footer {
  border-bottom: none;
  height: $card-title-height;
  font-size: $card-header-font-size;
  $vertical-padding: calc(($card-title-height - $card-header-font-size) / 2);
  padding: $vertical-padding 22px;
  background-color: $bootstrap-panel-header-bg;
}

.card-title {
  font-weight: $font-normal;
  font-size: $card-header-font-size;
  text-transform: uppercase;
  opacity: 0.9;
  color: $card-text-color;
}
.card-group .card.accordion-card {
  .card-header {
    border-bottom: 0;
  }
}

.p-with-code {
  line-height: 1.5em;
}

.contextual-example-card {
  height: 120px;
}

.footer-card {
  height: 142px;
}

.light-text {
  font-weight: $font-light;
}
