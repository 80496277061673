@import "card";
@import "dropdown";
@import "tabs";
@import "progress";
@import "modal";
.app-loading {
  background: #20212433;
}

:focus {
  outline: none !important;
}

.form-control:focus {
  color: #fff;
}

select.form-control {
  height: 35px !important;
}

.input-group {
  .input-group-btn {
    padding-left: 8px;
    button {
      height: 35px;
    }
  }
}

.table {
  thead {
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    th {
      font-weight: 500;
    }
  }
}