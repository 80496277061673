$font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

$resXXL: 1280px;
$resXL: 1170px;
$resL: 991px;
$resM: 768px;
$resS: 660px;
$resXS: 500px;
$resXXS: 435px;
$resMin: 320px;

$top-height: 66px;

$small-card-height: 114px;
$xsmall-card-height: 187px;
$medium-card-height: 400px;
$extra-medium-card-height: 550px;
$large-card-height: 974px;

$default-animation-duration: 0.2s;
$default-animation-style: ease-out;

$assets-root: '/assets';
$images-root: '/img/';
$fonts-root: '/fonts/';
$font-thin: 100;
$font-light: 300;
$font-normal: 400;
$font-bold: 700;
$font-bolder: 500;
$font-ultraBold: 900;

$facebook-color: #3b5998;
$twitter-color: #55acee;
$google-color: #dd4b39;
$google-color: rgba(255, 255, 255, 0.1);
$linkedin-color: #0177B5;
$github-color: #6b6b6b;
$stackoverflow-color: #2F96E8;
$dribble-color: #F26798;
$behace-color: #0093FA;
