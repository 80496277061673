/* You can add global styles to this file, and also import other style files */

// @import "custom";
@import "~bootstrap/scss/bootstrap";
@import '@angular/material/theming';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import 'assets/igniteui-angular/themes/index'; // contains fixes with deprecated warnings

// Don't forget to include the igx-core first
@include igx-core();

// the default color palette is passed to the global theme
@include igx-light-theme($default-palette);


// Plus imports for other components in your app.
body {
  background-color: #fafafa;
}

main,
.isFull {
  background-color: #fafafa;
}

body *:not(li)>a {
  transition: color 0.2s ease;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
}

a,
a:hover {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  width: 100%;
  margin-top: 0;
}

.card__list {
  &__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__key {
    flex-basis: 33.33%;
    padding-right: 8px;
    margin-bottom: 10px;
  }

  &__value {
    flex: 1;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;

    span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.info {
  color: #ffc107;
}

.warn {
  color: #f44336;
}

.success {
  color: rgb(76, 175, 80);
}

.m-l {
  margin-left: 16px !important;
}

.m-t-b {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-t-n {
  margin-top: 0;
}

.m-b-n {
  margin-bottom: 0;
}

.b-n {
  bottom: 0 !important;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.mat-form-field {
  width: 100%;
}

.mat-dialog-container {
  border-radius: 8px !important;
}

.btn-cnt {

  :not(:first-child).mat-raised-button,
  :not(:first-child).mat-button {
    margin-left: 15px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.54);
  box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.mat-list-option:hover,
.mat-list-option.mat-list-item-focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item.mat-list-item-focus {
  border-radius: 0 20px 20px 0;
}

.mat-list .mat-list-item .mat-list-item-ripple,
.mat-list .mat-list-option .mat-list-item-ripple,
.mat-nav-list .mat-list-item .mat-list-item-ripple,
.mat-nav-list .mat-list-option .mat-list-item-ripple,
.mat-selection-list .mat-list-item .mat-list-item-ripple,
.mat-selection-list .mat-list-option .mat-list-item-ripple {
  border-radius: 0 20px 20px 0;
  border: 1px solid transparent;
}

.mat-list,
.mat-nav-list,
.mat-selection-list {
  padding-right: 8px;
}

.mat-tab-nav-bar {
  white-space: nowrap;
  overflow: auto !important;
}

.mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87) !important;
  background: #fafafa !important;
}

.filter-set-search {
  .mat-form-field {
    font-size: 14px;
  }

  .mat-form-field-wrapper {
    margin: 0;
    padding: 0 8px 4px;
  }
}

::ng-deep .check-icon {
  position: absolute;
  top: 12px;
  right: 0;
}

::ng-deep igc-trial-watermark {
  display: none;
}

.mat-paginator-page-size-select {
  width: 56px !important;
}


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat-core();
// Define the default theme (same as the example above).
$candy-app-primary: mat-palette($mat-deep-purple, A400);
$candy-app-accent: mat-palette($mat-pink, A400);
$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent);
// Include the default theme styles.
@include angular-material-theme($candy-app-theme);

nav-menu-item {
  color: rgba(0, 0, 0, 0.54);
}

data-table-filter-attr-menu-item {
  color: mat-color($candy-app-primary);
}

.selected {
  color: mat-color($candy-app-primary);
}

*:not(li)>a {
  color: mat-color($candy-app-primary);

  &:hover {
    color: mat-color($candy-app-primary);
  }
}

.steps-row {
  background-color: #f3f3f3;
  border-radius: 4px;
  border: 1px solid #8d8d8d36;
}

.close-tab-icon {
  border: 1px solid transparent;
  &:hover{
    border: 1px solid #8d8d8de8;
  }
}

// Define an alternate dark theme.
$dark-primary: mat-palette($mat-teal, A700);
$dark-accent: mat-palette($mat-amber, A200, A100, A400);
$dark-warn: mat-palette($mat-deep-orange);
$dark-theme: mat-dark-theme($dark-primary, $dark-accent, $dark-warn);
$dark-theme-text-color: rgba(255, 255, 255, 0.7);
$dark-theme-border: rgba(255, 255, 255, 0.12);
$dark-theme-background-hover: rgba(255, 255, 255, 0.03);

// Include the alternative theme styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.teal-dark-theme` will be affected by this alternate dark theme instead of the default theme.
.teal-dark-theme {
  @include angular-material-theme($dark-theme);
  @include igx-dark-theme($default-palette);

  main,
  .mat-drawer-container,
  .isFull {
    background-color: #202124 !important;
  }

  *:not(li)>a {
    color: mat-color($dark-primary);

    &:hover {
      color: mat-color($dark-primary);
    }
  }

  .content-top {
    color: rgba(255, 255, 255, 0.7) !important;
  }

  .al-footer {
    background-color: mat-color($dark-primary);
  }

  .selected {
    color: mat-color($dark-primary);
  }

  .card {
    background-color: #363739;
    color: $dark-theme-text-color;

    .card-header,
    .card .card-footer {
      background: #303133;
      color: $dark-theme-text-color;

      .card-title {
        color: $dark-theme-text-color;
      }
    }

    .inner-json {
      background-color: rgba(255, 255, 255, .6);
    }
  }

  .toolbar-profile-menu a,
  .toolbar-profile-menu span,
  .toolbar-profile-menu .mat-menu-item .mat-icon,
  mat-radio-button .mat-icon,
  mat-list-item .mat-icon {
    color: $dark-theme-text-color;
  }

  nav-menu-item {
    color: $dark-theme-text-color;
  }

  data-table-filter-attr-menu-item {
    color: mat-color($dark-primary);
  }

  .pie-charts {
    .pie-chart-item {
      .chart-icon {
        background-color: $dark-theme-text-color;
      }
    }
  }

  .mat-table {
    background-color: #363739 !important;
  }

  .mat-button-toggle-group {
    &>.mat-button-toggle-checked {
      background: #303133 !important;
      color: mat-color($dark-primary) !important;
    }

    &>.mat-button-toggle {
      color: $dark-theme-text-color;
    }
  }

  .mat-drawer-side {
    border-right: none !important;
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.2) !important;
    color: mat-color($dark-primary);
  }

  .mat-option {
    color: $dark-theme-text-color !important;
  }

  .mat-option:hover:not(.mat-option-disabled),
  .mat-option:focus:not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.2) !important;
  }

  .mat-dialog-container {
    background-color: #363739 !important;
  }

  .mat-card,
  .mat-paginator,
  .mat-expansion-panel,
  .mat-drawer,
  .mat-select-content,
  .mat-stepper-horizontal,
  .mat-stepper-vertical,
  .mat-menu-panel {
    background-color: #363739 !important;
  }

  .mat-fab,
  .mat-mini-fab,
  .mat-icon-button {
    color: $dark-theme-text-color !important;
  }

  .mat-raised-button {
    color: #fff !important;
  }

  .mat-autocomplete-panel {
    background-color: #363739 !important;
  }

  .mat-snack-bar-container {
    color: $dark-theme-text-color !important;
    background: #363739 !important;
  }

  chartist-chart {
    .ct-label {
      color: $dark-theme-text-color !important;
    }

    .ct-grid {
      stroke: $dark-theme-text-color !important;
    }

    .ct-chart-bar,
    .ct-chart-line {
      .ct-series-a {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut,
        .ct-slice-pie {
          stroke: mat-color($dark-primary);
        }

        .ct-slice-pie,
        .ct-area {
          fill: mat-color($dark-primary);
        }
      }

      .ct-series-b {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut,
        .ct-slice-pie {
          stroke: mat-color($dark-accent);
        }

        .ct-slice-pie,
        .ct-area {
          fill: mat-color($dark-accent);
        }
      }

      .ct-series-c {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut,
        .ct-slice-pie {
          stroke: mat-color($candy-app-primary);
        }

        .ct-slice-pie,
        .ct-area {
          fill: mat-color($candy-app-primary);
        }
      }

      .ct-series-d {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut,
        .ct-slice-pie {
          stroke: mat-color($candy-app-accent);
        }

        .ct-slice-pie,
        .ct-area {
          fill: mat-color($candy-app-accent);
        }
      }

      .ct-series-e {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut,
        .ct-slice-pie {
          stroke: mat-color($dark-warn);
        }

        .ct-slice-pie,
        .ct-area {
          fill: mat-color($dark-warn);
        }
      }
    }
  }

  .basic-table-template {

    table,
    .table {
      thead {
        tr {
          border-bottom: 1px solid $dark-theme-border !important;
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid $dark-theme-border !important;
          color: #fff !important;

          &:hover {
            background-color: $dark-theme-background-hover !important;
          }

          &:last-child {
            border-bottom: 1px solid $dark-theme-border !important;
          }
        }
      }
    }
  }

  .cell-link {
    color: $dark-theme-text-color !important;
  }

  table.table>thead>tr {
    color: $dark-theme-text-color;
    border-bottom: 1px solid $dark-theme-border;
  }

  table tbody tr {
    border-bottom: 1px solid $dark-theme-border !important;
    color: #fff !important;
  }

  hr {
    border-top: 1px solid $dark-theme-border;
  }

  .domain-list.mat-list .mat-icon {
    color: $dark-theme-text-color;
  }

  .categories-list .list-item {
    border-bottom: 1px solid $dark-theme-border;

    &:hover {
      background-color: $dark-theme-background-hover;
    }
  }

  .domain-settings h3 {
    color: $dark-theme-text-color;
  }

  .opensrs span,
  .opensrs strong,
  .opensrs p {
    color: $dark-theme-text-color;
  }

  div.img {
    background-color: #fff !important;
  }

  .merchants {
    li {
      &.isCurrent {
        background-color: $dark-theme-background-hover;
      }

      &:hover {
        background-color: $dark-theme-background-hover;
      }
    }
  }

  ul.list-group document-operation-item li.list-group-item {
    border-bottom: 1px solid $dark-theme-border;
  }

  .container .my-drop-zone {
    border-color: $dark-theme-border;

    h3 {
      color: $dark-theme-text-color;
    }
  }

  .queue-table h3 {
    color: $dark-theme-text-color;
  }

  .search-form {
    mat-form-field {
      color: $dark-theme-text-color !important;
    }
  }

  p.total {
    color: $dark-theme-text-color;

    & strong {
      color: $dark-theme-text-color !important;
    }
  }

  .not-found h3 {
    color: $dark-theme-text-color;
  }

  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: $dark-theme-text-color;
  }

  .ql-snow .ql-stroke {
    stroke: $dark-theme-text-color;
  }

  .ql-snow .ql-picker {
    color: $dark-theme-text-color;
  }

  .ql-editor.ql-blank::before {
    color: $dark-theme-text-color;
  }

  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: mat-color($dark-primary);
  }

  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: mat-color($dark-primary);
  }

  .ql-snow.ql-toolbar button:hover .ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: mat-color($dark-primary);
  }

  .ql-snow .ql-picker-options {
    background-color: #363739;
  }

  span.tags {
    background-color: #303133 !important;
  }

  spinner div.wrapper {
    background-color: rgba(48, 48, 48, .2);
  }

  path#icon {
    fill: #fff;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: $dark-theme-text-color;
  }

  .steps-row {
    background-color: #00000036;
    border-radius: 4px;
    border: 1px solid hsla(0,0%,100%,.2);
  }

  .close-tab-icon {
    border: 1px solid transparent;
    &:hover {
      border: 1px solid hsla(0,0%,100%,.2);
    }
  }
}

$deep-orange-grey: #999;
$deep-orange-dark-grey: #666;
$deep-orange-black: #333;
$deep-orange-border: #f2f2f2;
$deep-orange-primary: mat-palette($mat-deep-orange);
$deep-orange-accent: mat-palette($mat-deep-orange, A700);
$deep-orange-warn: mat-palette($mat-light-blue);
$deep-orange-theme: mat-light-theme($deep-orange-primary, $deep-orange-accent, $deep-orange-warn);

.dev-orange-theme {
  @include angular-material-theme($deep-orange-theme);

  main,
  .isFull {
    background-color: rgb(239, 239, 239);
  }

  .selected {
    color: mat-color($deep-orange-primary);
  }

  .mat-toolbar.mat-primary {
    background: linear-gradient(#f70, #f30);
  }

  *:not(li)>a {
    color: mat-color($deep-orange-primary);

    &:hover {
      color: mat-color($deep-orange-primary);
    }
  }

  .al-footer {
    background-color: #333;
  }

  .card {
    background-color: #fff;
    color: $deep-orange-dark-grey;

    *:not(li)>a {
      color: mat-color($deep-orange-primary);

      &:hover {
        text-decoration: underline;
      }
    }

    .card-header {
      border-bottom: 1px solid $deep-orange-border;
    }

    .card-footer {
      border-top: 1px solid $deep-orange-border;
    }

    .card-header,
    .card .card-footer {
      background: #fff;
      color: $deep-orange-dark-grey;

      .card-title {
        color: $deep-orange-grey;
      }
    }

    .inner-json {
      background-color: rgba(0, 0, 0, .03);

      button {
        color: $deep-orange-grey;
      }
    }
  }

  .toolbar-profile-menu a,
  .toolbar-profile-menu span,
  .toolbar-profile-menu .mat-menu-item .mat-icon {
    color: $deep-orange-dark-grey;
  }

  .toolbar-profile-menu a:hover {
    & .mat-menu-item {
      span {
        color: mat-color($deep-orange-primary);
      }

      .mat-icon {
        color: mat-color($deep-orange-primary);
      }
    }
  }

  nav-menu-item {
    color: $deep-orange-dark-grey;
  }

  data-table-filter-attr-menu-item {
    color: mat-color($deep-orange-primary);
  }

  .pie-charts {
    .pie-chart-item {
      .chart-icon {
        background-color: $deep-orange-black;
      }
    }
  }

  .mat-button-toggle-group {
    &>.mat-button-toggle-checked {
      background: #fff !important;
      color: mat-color($deep-orange-primary) !important;
    }

    &>.mat-button-toggle {
      color: $deep-orange-grey;

      .mat-button-toggle-focus-overlay {
        background: transparent !important;
      }
    }
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: mat-color($deep-orange-primary);
  }

  .mat-option {
    color: $deep-orange-grey;
  }

  .mat-option:hover:not(.mat-option-disabled),
  .mat-option:focus:not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04) !important;
    color: mat-color($deep-orange-primary);
  }

  chartist-chart {
    .ct-label {
      color: $deep-orange-dark-grey !important;
    }

    .ct-grid {
      stroke: $deep-orange-grey !important;
    }

    .ct-chart-bar,
    .ct-chart-line {
      .ct-series-a {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut,
        .ct-slice-pie {
          stroke: mat-color($deep-orange-primary);
        }

        .ct-slice-pie,
        .ct-area {
          fill: mat-color($deep-orange-primary);
        }
      }

      .ct-series-b {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut,
        .ct-slice-pie {
          stroke: mat-color($dark-accent);
        }

        .ct-slice-pie,
        .ct-area {
          fill: mat-color($dark-accent);
        }
      }

      .ct-series-c {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut,
        .ct-slice-pie {
          stroke: mat-color($candy-app-primary);
        }

        .ct-slice-pie,
        .ct-area {
          fill: mat-color($candy-app-primary);
        }
      }

      .ct-series-d {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut,
        .ct-slice-pie {
          stroke: mat-color($candy-app-accent);
        }

        .ct-slice-pie,
        .ct-area {
          fill: mat-color($candy-app-accent);
        }
      }

      .ct-series-e {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut,
        .ct-slice-pie {
          stroke: mat-color($dark-warn);
        }

        .ct-slice-pie,
        .ct-area {
          fill: mat-color($dark-warn);
        }
      }
    }
  }

  .basic-table-template {

    table,
    .table {
      thead {
        tr {
          border-bottom: 1px solid $deep-orange-border !important;
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid $deep-orange-border !important;
          color: $deep-orange-black !important;

          &:hover {
            background-color: rgba(0, 0, 0, 0.04) !important;
          }

          &:last-child {
            border-bottom: 1px solid $deep-orange-border !important;
          }
        }
      }
    }
  }

  .cell-link {
    color: mat-color($deep-orange-primary) !important;
  }

  table.table>thead>tr {
    color: $deep-orange-black;
    border-bottom: 1px solid $deep-orange-border;
  }

  table tbody tr {
    border-bottom: 1px solid $deep-orange-border !important;
    color: $deep-orange-dark-grey !important;
  }

  .mat-form-field {
    width: 100%;
  }

  .domain-list.mat-list .mat-icon {
    color: $deep-orange-black;
  }

  .categories-list .list-item {
    border-bottom: 1px solid $deep-orange-border;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .domain-settings h3 {
    color: $deep-orange-grey;
  }

  .opensrs span,
  .opensrs strong,
  .opensrs p {
    color: $deep-orange-black;
  }

  div.img {
    background-color: $deep-orange-black !important;
  }

  .merchants {
    li {
      &.isCurrent {
        background-color: rgba(0, 0, 0, 0.04);
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }

  ul.list-group document-operation-item li.list-group-item {
    border-bottom: 1px solid $deep-orange-border;
  }

  .container .my-drop-zone {
    border-color: $deep-orange-border;

    h3 {
      color: $deep-orange-grey;
    }
  }

  .queue-table h3 {
    color: $deep-orange-grey;
  }

  .search-form {
    mat-form-field {
      color: $deep-orange-black !important;
    }
  }

  p.total {
    color: $deep-orange-dark-grey;

    & strong {
      color: $deep-orange-black !important;
    }
  }

  .not-found h3 {
    color: $deep-orange-dark-grey;
  }

  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: $deep-orange-dark-grey;
  }

  .ql-snow .ql-stroke {
    stroke: $deep-orange-dark-grey;
  }

  .ql-snow .ql-picker {
    color: $deep-orange-dark-grey;
  }

  .ql-editor.ql-blank::before {
    color: $deep-orange-dark-grey;
  }

  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: mat-color($deep-orange-primary);
  }

  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: mat-color($deep-orange-primary);
  }

  .ql-snow.ql-toolbar button:hover .ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: mat-color($deep-orange-primary);
  }

  .ql-snow .ql-picker-options {
    background-color: #fff;
  }

  spinner div.wrapper {
    background-color: rgba(48, 48, 48, .2);
  }

  path#icon {
    fill: $deep-orange-black;
  }

  .mat-snack-bar-container {
    background-color: #fff;
    color: #666;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: $deep-orange-dark-grey;
  }
}

// Define an alternate amber theme.
$amber-primary: mat-palette($mat-amber);
$amber-accent: mat-palette($mat-amber, A200, A100, A400);
$amber-warn: mat-palette($mat-deep-orange);
$amber-theme: mat-dark-theme($amber-primary, $amber-accent, $amber-warn);

.amber-dark-theme {
  @include angular-material-theme($amber-theme);
  @include igx-dark-theme($default-palette);

  main,
  .isFull {
    background-color: mat-color($amber-primary);
  }

  .selected {
    color: mat-color($amber-primary);
  }

  .mat-drawer-container {
    background-color: mat-color($amber-primary);
  }

  *:not(li)>a {
    color: mat-color($amber-primary);

    &:hover {
      color: mat-color($amber-primary);
    }
  }

  .al-footer {
    background-color: #000;
  }

  .mat-drawer {
    background-color: rgba(0, 0, 0, .87);
  }

  nav-menu-item {
    color: $dark-theme-text-color;
  }

  data-table-filter-attr-menu-item {
    color: mat-color($amber-primary);
  }

  .mat-toolbar.mat-primary {
    background: #000 !important;
    color: #fff !important;
  }

  .card {
    background-color: rgba(0, 0, 0, .87);
    color: $dark-theme-text-color;

    .card-header,
    .card .card-footer {
      background: #000;
      color: $dark-theme-text-color;

      .card-title {
        color: $dark-theme-text-color;
      }
    }

    .inner-json {
      background-color: #000;

      pre {
        color: #fff;
      }
    }
  }

  .toolbar-profile-menu a,
  .toolbar-profile-menu span,
  .toolbar-profile-menu .mat-menu-item .mat-icon {
    color: $dark-theme-text-color;
  }

  .list-title {
    color: #fff;
  }

  .mat-menu-panel {
    background: #000 !important;
  }

  .mat-menu-item:hover:not([disabled]) {
    background: $dark-theme-background-hover;
  }

  div.menu-item.al-sidebar-list-item.selected {
    a.al-sidebar-list-link {
      color: mat-color($amber-primary) !important;
    }
  }

  .al-sidebar-list-item {
    .selected {
      a {
        color: mat-color($amber-primary) !important;
      }
    }

    .selected:not(.with-sub-menu) {
      a.al-sidebar-list-link {
        toolbar-profile-menu b {
          color: $dark-theme-text-color;
        }
      }
    }
  }

  .pie-charts {
    .pie-chart-item {
      .chart-icon {
        background-color: $dark-theme-text-color;
      }
    }
  }

  .mat-button-toggle-group {
    &>.mat-button-toggle-checked {
      background: #000 !important;
      color: mat-color($amber-primary) !important;
    }

    &>.mat-button-toggle {
      color: $dark-theme-text-color;
    }
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    background: #000 !important;
    color: mat-color($amber-primary);
  }

  .mat-option {
    color: $dark-theme-text-color !important;
  }

  .mat-option:hover:not(.mat-option-disabled),
  .mat-option:focus:not(.mat-option-disabled) {
    background: rgba(255, 255, 255, 0.1) !important;
  }

  .mat-select-content,
  .mat-select-panel-done-animating {
    background: #000;
  }

  .mat-card {
    background: rgba(0, 0, 0, .87);
  }

  .mat-flat-button.mat-primary,
  .mat-raised-button.mat-primary,
  .mat-mini-fab.mat-primary {
    color: #000;
  }

  .mat-fab.mat-primary {
    background: #000;
    color: #fff;
  }

  .mat-dialog-container {
    background: rgba(0, 0, 0, .87);
  }

  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #000;
  }

  .mat-tab-link {
    opacity: 1;
    color: #000;

    &:hover {
      color: #fff;
    }
  }

  .mat-snack-bar-container {
    color: $dark-theme-text-color !important;
    background: rgba(0, 0, 0, .87);
  }

  .al-breadcrumb {
    color: #000;

    li a {
      color: #000 !important;
    }
  }

  .content-top {
    color: rgba(0, 0, 0, 0.54);
  }

  .mat-autocomplete-panel {
    background: #000;
  }

  .mat-expansion-panel {
    background: #000;
  }

  chartist-chart {
    .ct-label {
      color: $dark-theme-text-color !important;
    }

    .ct-grid {
      stroke: $dark-theme-text-color !important;
    }

    .ct-chart-bar,
    .ct-chart-line {
      .ct-series-a {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut,
        .ct-slice-pie {
          stroke: mat-color($amber-primary);
        }

        .ct-slice-pie,
        .ct-area {
          fill: mat-color($amber-primary);
        }
      }

      .ct-series-b {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut,
        .ct-slice-pie {
          stroke: mat-color($amber-accent);
        }

        .ct-slice-pie,
        .ct-area {
          fill: mat-color($amber-accent);
        }
      }

      .ct-series-c {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut,
        .ct-slice-pie {
          stroke: mat-color($candy-app-primary);
        }

        .ct-slice-pie,
        .ct-area {
          fill: mat-color($candy-app-primary);
        }
      }

      .ct-series-d {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut,
        .ct-slice-pie {
          stroke: mat-color($candy-app-accent);
        }

        .ct-slice-pie,
        .ct-area {
          fill: mat-color($candy-app-accent);
        }
      }

      .ct-series-e {

        .ct-bar,
        .ct-line,
        .ct-point,
        .ct-slice-donut,
        .ct-slice-pie {
          stroke: mat-color($amber-warn);
        }

        .ct-slice-pie,
        .ct-area {
          fill: mat-color($amber-warn);
        }
      }
    }
  }

  .basic-table-template {

    table,
    .table {
      thead {
        tr {
          border-bottom: 1px solid $dark-theme-border !important;
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid $dark-theme-border !important;
          color: #fff !important;

          &:hover {
            background-color: $dark-theme-background-hover !important;
          }

          &:last-child {
            border-bottom: 1px solid $dark-theme-border !important;
          }
        }
      }
    }
  }

  .cell-link {
    color: $dark-theme-text-color !important;
  }

  table.table>thead>tr {
    color: $dark-theme-text-color;
    border-bottom: 1px solid $dark-theme-border;
  }

  table tbody tr {
    border-bottom: 1px solid $dark-theme-border !important;
    color: #fff !important;
  }

  hr {
    border-top: 1px solid $dark-theme-border;
  }

  .domain-list.mat-list .mat-icon {
    color: $dark-theme-text-color;
  }

  .categories-list .list-item {
    border-bottom: 1px solid $dark-theme-border;

    &:hover {
      background-color: $dark-theme-background-hover;
    }
  }

  .domain-settings h3 {
    color: $dark-theme-text-color;
  }

  .opensrs span,
  .opensrs strong,
  .opensrs p {
    color: $dark-theme-text-color;
  }

  div.img {
    background-color: #fff !important;
  }

  .merchants {
    li {
      &.isCurrent {
        background-color: $dark-theme-background-hover;
      }

      &:hover {
        background-color: $dark-theme-background-hover;
      }
    }
  }

  ul.list-group document-operation-item li.list-group-item {
    border-bottom: 1px solid $dark-theme-border;
  }

  .container .my-drop-zone {
    border-color: $dark-theme-border;

    h3 {
      color: $dark-theme-text-color;
    }
  }

  .queue-table h3 {
    color: $dark-theme-text-color;
  }

  .search-form {
    mat-form-field {
      color: $dark-theme-text-color !important;
    }

    &.gallery-search {
      color: #000 !important;

      .mat-form-field-appearance-legacy .mat-form-field-label {
        color: #fff;
      }

      .mat-form-field.mat-focused .mat-form-field-label {
        color: #000;
      }

      .mat-form-field-appearance-legacy .mat-form-field-underline {
        background-color: #fff;
      }

      .mat-input-element {
        caret-color: #000;
        color: #000;
      }

      .mat-form-field.mat-focused .mat-form-field-ripple {
        background-color: #000;
      }
    }
  }

  p.total {
    color: $dark-theme-text-color;

    & strong {
      color: $dark-theme-text-color !important;
    }
  }

  .more-btn {
    background: #000;
    color: #fff !important;
  }

  .not-found h3 {
    color: $dark-theme-text-color;
  }

  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: $dark-theme-text-color;
  }

  .ql-snow .ql-stroke {
    stroke: $dark-theme-text-color;
  }

  .ql-snow .ql-picker {
    color: $dark-theme-text-color;
  }

  .ql-editor.ql-blank::before {
    color: $dark-theme-text-color;
  }

  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: mat-color($amber-primary);
  }

  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: mat-color($amber-primary);
  }

  .ql-snow.ql-toolbar button:hover .ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: mat-color($amber-primary);
  }

  .ql-snow .ql-picker-options {
    background-color: #303030;
  }

  span.tags {
    background-color: #000 !important;
  }

  spinner div.wrapper {
    background-color: rgba(48, 48, 48, .2);
  }

  path#icon {
    fill: #fff;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: $dark-theme-text-color;
  }
}

.table-menu {
  z-index: 1;

  &+* {
    z-index: 2;
  }
}

hr:not([size]) {
  height: 0.2px!important;
}

.relative {
  position: relative!important
}
