body,
html {
  background-color: #222;
  height: 100%;
}

.app-loading {
  position: absolute;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.preload-logo {
  width: 300px;
  height: 300px;
  background: url(./logo.svg) center center no-repeat;
}

.app-loading .spinner {
  height: 300px;
  width: 300px;
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.app-loading .spinner .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-width: 0.5;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: rgba(255, 255, 255, 0.87);
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}