$default: rgba(#000000, 0.2);
$body-bg: #F0F3F4;

$default-text: #ffffff;
$content-text: $default-text;
$help-text: #eeeeee;

$label-text: #ffffff;
$disabled: #dddddd;
$disabled-bg: transparent;
$border: #ffffff;
$border-light: rgba(#ffffff, 0.2);
$input-border: transparent;
$input-background: rgba(#000000, 0.15);
$dropdown-text: #7d7d7d;
$modal-color: #222222;

$sidebar: rgba(#000000, 0.5);
$sidebar-text: #ffffff;

$mail-box: whitesmoke;
$auth-panel-background: rgba(#000000, 0.55);
$progress-background: rgba(#000000, 0.15);
$progress-default: #ffffff;

$bootstrap-panel-radius: 5px;
$bootstrap-panel-text: #7d7d7d;
$bootstrap-panel-bg: #ffffff;
$bootstrap-panel-header-bg: $bootstrap-panel-bg;
$bootstrap-panel-header-border: 1px solid rgba(0, 0, 0, 0.12);
$bootstrap-panel-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);

$primary: #209e91 !default;
$info: #2dacd1 !default;
$success: #90b900 !default;
$warning: #dfb81c !default;
$danger: #e85656 !default;

$primary-light: tint($primary, 30%);
$info-light: tint($info, 30%);
$success-light: tint($success, 30%);
$warning-light: tint($warning, 30%);
$danger-light: tint($danger, 30%);

$primary-dark: shade($primary, 15%);
$info-dark: shade($info, 15%);
$success-dark: shade($success, 15%);
$warning-dark: shade($warning, 15%);
$danger-dark: shade($danger, 15%);

$primary-bg: tint($primary, 20%);
$info-bg: tint($info, 20%);
$success-bg: tint($success, 20%);
$warning-bg: tint($warning, 20%);
$danger-bg: tint($danger, 20%);

$activelink: rgb(255, 255, 0);
$hoverlink: rgb(250, 255, 241);

@mixin body-bg($link) {
  background-color: $body-bg;
  
  $mainBgUrl: $assets-root + $images-root + $link;

  &::before {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url($mainBgUrl) no-repeat center center;
    background-size: cover;
    will-change: transform;
    z-index: 0;
    transition: all 225ms ease-in-out;
  }
}

@mixin additional-bg() {
  //display: block; // additional background layer, hidden by default
}
