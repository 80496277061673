.modal-content {
  &#emailForm {
    label {
      color: #fff !important;
    }
    input {
      color: #fff !important;
    }
    .modal-header{
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}
