@import '@angular/material/theming';
@import 'src/styles.scss';
$left-space: 200px;
@include scrollbars(0px, transparent, rgba(0, 0, 0, 0));
html {
    position: relative;
    min-width: 320px;
}

html,
body {
    min-height: 100%;
    height: 100%;
    min-width: $resMin;
}

main {
    min-height: 100%;
    position: relative;
    font: 14px/16px $font-family;
    color: $default-text;
    .additional-bg {
        display: none;
        @include additional-bg();
    }
}

.list-group {
    background: transparent;
    .list-group-item {
        background: transparent;
    }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    html {
        overflow: hidden;
        height: 100%;
    }
    body {
        overflow: auto;
        height: 100%;
    }
}

a {
    transition: color 0.5s ease;
    outline: 0 !important;
}

.body-bg {
    display: none;
}

.al-header {
    display: block;
    height: 49px;
    margin: 0;
    background-repeat: repeat-x;
    position: relative;
    z-index: 905;
    color: #444444;
}

.al-main {
    padding: 64px 0 54px;
    min-height: 500px;
    position: relative;
}

.al-pre-top {
    height: 128px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.al-footer {
    height: 54px;
    padding: 21px 20px 0;
    width: 100%;
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.87);
    background-color: mat-color($candy-app-primary);
    transition: padding-left 0.5s ease;
}

.al-footer-main {
    float: left;
    margin-left: 20px;
    a {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.87);
        padding: 0 4px;
        &:hover {
            text-decoration: underline;
        }
    }
}

.al-copy {
    float: left;
}

.al-footer-right {
    float: right;
    margin-right: 12px;
    i {
        margin: 0 4px;
        color: #ff4081;
        font-size: 12px;
    }
    a {
        margin-left: 4px;
        color: $default-text;
        &:hover {
            color: $danger;
        }
    }
}

.al-share {
    margin: -6px 0 0 12px;
    padding: 0;
    list-style: none;
    float: left;
    li {
        list-style: none;
        float: left;
        margin-left: 16px;
        i {
            cursor: pointer;
            transition: all 0.1s ease;
            color: white;
            padding: 6px;
            box-sizing: content-box;
            font-size: 16px;
            &:hover {
                transform: scale(1.2);
            }
        }
        i.fa-facebook-square {
            color: $facebook-color;
        }
        i.fa-twitter-square {
            color: $twitter-color;
        }
        i.fa-google-plus-square {
            color: $google-color;
        }
    }
}

.al-content {
    padding: 0 32px 32px;
    overflow: hidden;
}

.vis-hidden {
    visibility: hidden;
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.icon-up,
.icon-down {
    width: 5px;
    height: 13px;
    display: block;
}

.disable-text-selection {
    -webkit-touch-callout: none;
    user-select: none;
}

.align-right {
    text-align: right
}

.amcharts-chart-div>a {
    font-size: 6px !important;
}

.content-panel {
    padding-left: 22px;
    padding-top: 26px;
}

@media (max-width: 590px) {
    .al-footer-right {
        float: none;
        margin: 8px 0 6px 20px;
    }
    .al-footer {
        height: 56px;
        padding: 0 20px;
        text-align: center;
    }
    .al-main {
        padding-bottom: 76px;
    }
    .al-footer-main {
        float: none;
        display: inline-block;
    }
}

.full-invisible {
    visibility: hidden !important;
    * {
        visibility: hidden !important;
    }
}

.irs-grid-text {
    color: $default-text !important;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.top-buffer {
    margin-top: 15px !important;
}

.bottom-buffer {
    margin-bottom: 15px !important;
}

.table>:not(:first-child) {
  border-top: none !important;
}
